import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout/Index"
import { Card } from "react-bootstrap"
import { withTrans } from "../i18n/withTrans"
import moment from "moment"

const Index = ({ data, location: { href }, t, i18n }) => {
  let place = data?.wpKegiatan?.event?.regionEvent
    ? data.wpKegiatan.event.regionEvent.split(": ")
    : ["", ""]

  return (
    <Layout>
      <Container className="kegiatan-detail">
        <Row>
          <Col md={6}>
            <label className="title">{data?.wpKegiatan?.title}</label>
            <Row style={{ marginBottom: 30 }}>
              <Col>
                <span>{t("agenda.registrationDate")}</span>
                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#389D64"
                    className="bi bi-clock me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg>
                  <span style={{ fontWeight: "bold" }}>
                    {data?.wpKegiatan?.event?.tanggalRegistrasi
                      ?.tanggalMulai ? (
                      <>
                        {/* {moment( */}
                        {data.wpKegiatan.event.tanggalRegistrasi?.tanggalMulai}
                        {/* ).format("DD MMM YYYY")}{" "} */}- {/* {moment( */}
                        {
                          data.wpKegiatan.event.tanggalRegistrasi
                            ?.tanggalBerakhir
                        }
                        {/* ).format("DD MMM YYYY")} */}
                      </>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
              </Col>
              <Col>
                <span>{t("agenda.dateAndTime")}</span>
                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#389D64"
                    className="bi bi-calendar-event me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                  </svg>
                  <span style={{ fontWeight: "bold" }}>
                    {/* {moment( */}
                    {data?.wpKegiatan?.event?.tanggalKegiatan?.tanggalMulai}
                    {/* ).format("DD MMMM YYYY")}{" "} */}- {/* {moment( */}
                    {data?.wpKegiatan?.event?.tanggalKegiatan?.tanggalBerakhir}
                    {/* ).format("DD MMMM YYYY")} */}
                  </span>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: 30 }}>
              <Col>
                <span>{t("agenda.place")}</span>
                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#389D64"
                    className="bi bi-geo-alt-fill me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                  <span style={{ fontWeight: "bold" }}>
                    {data?.wpKegiatan?.event?.activityAddress ?? "-"}
                  </span>
                </div>
              </Col>
              <Col>
                <div style={{ marginBottom: 30 }}>
                  <span>{t("agenda.contactPerson")}</span>
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#389D64"
                      className="bi bi-telephone me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                    <span>
                      {data?.wpKegiatan?.event?.kontakPerson?.nama &&
                      data?.wpKegiatan?.event?.kontakPerson?.noTelepon ? (
                        <>
                          {data?.wpKegiatan?.event?.kontakPerson?.nama ?? "-"}
                          <br />
                          <strong>
                            (
                            {data?.wpKegiatan?.event?.kontakPerson?.noTelepon ??
                              "-"}
                            )
                          </strong>
                        </>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <div className="d-flex">
              <Col className="ms-0">
                <span style={{ opacity: "0.8" }}>{t("agenda.startForm")}</span>
                <div>
                  <span className="value">Rp 10.500.000</span>
                </div>
              </Col>
            </div> */}
            <div className="description-card">
              <Card>
                <Card.Body>
                  <div className="d-flex flex-column p-3">
                    <span className="title-desc">
                      {t("agenda.description")}
                    </span>
                    <div className="d-flex">
                      {data?.wpKegiatan?.event?.description
                        ? data.wpKegiatan.event.description
                        : "-"}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <div
              style={{ width: 500 }}
              className="d-flex justify-content-center align-items-start"
            >
              <img
                className=""
                src={data?.wpKegiatan?.event?.imgName?.sourceUrl}
                alt=""
                width="100%"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpKegiatan(id: { eq: $id }) {
      id
      slug
      language {
        id
        code
      }
      title
      event {
        tanggalKegiatan {
          tanggalMulai
          tanggalBerakhir
        }
        tanggalRegistrasi {
          tanggalMulai
          tanggalBerakhir
        }
        regionEvent
        imgName {
          sourceUrl
        }
        activityAddress
        description
        kontakPerson {
          nama
          noTelepon
        }
      }
    }
  }
`

export default withTrans(Index)
